import { ref, computed } from "vue";
import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import type { IAnnouncement } from "@/interfaces/announcement.interface";
import type { ITheme } from "@/interfaces/utility.interface";

export const useUtilityStore = defineStore("utility", () => {
  const appVersion = ref<string>("--");
  const isAnnualTargetAllowed = ref<boolean>(false);
  const isMonthlyTargetAllowed = ref<boolean>(false);
  const announcements = ref<IAnnouncement[]>([]);
  const theme = ref<ITheme | null>(null);
  const isTheme = ref(false);

  const currentAppVersion = computed(() => {
    return appVersion.value;
  });

  const currentAnnouncements = computed(() => {
    return announcements.value;
  });

  const isThemeVisible = computed(() => {
    return isTheme.value;
  });

  const currentTheme = computed(() => {
    return theme.value;
  });

  const isAnnualTargetAllowedComputed = computed(() => {
    return isAnnualTargetAllowed.value;
  }
  );

  const isMonthlyTargetAllowedComputed = computed(() => {
    return isMonthlyTargetAllowed.value;
  }
  );

  async function getAppInfo() {
    try {
      const response = await ApiService.get(`/api/utility/app-info/`);
      if (response.data.latest_release !== undefined) {
        appVersion.value = response.data.latest_release.version ?? "--";
      }

      if (response.data.announcements !== undefined) {
        announcements.value = response.data.announcements ?? [];
      }

      if (response.data.is_annual_target_allowed !== undefined) {
        isAnnualTargetAllowed.value = response.data.is_annual_target_allowed ?? false;
      }

      if (response.data.is_monthly_target_allowed !== undefined) {
        isMonthlyTargetAllowed.value = response.data.is_monthly_target_allowed ?? false;
      }

      if (response.data.theme !== undefined && response.data.theme) {
        theme.value = response.data.theme;
        isTheme.value = true;
      } else {
        theme.value = null;
        isTheme.value = false;
      }

      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getReleases() {
    try {
      const response = await ApiService.get(`/api/utility/releases/`);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getAIFixText(text:string){
    try {
      const response = await ApiService.post(`/api/utility/ai/fix-text/`,{data: text});
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getLastChangeLog(){
    try {
      const response = await ApiService.get(`/api/utility/releases/last-log/`);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getActivityNoteSummary(id:string|number,signal:AbortSignal){
    try {
      const response = await ApiService.get(`/api/utility/ai/fix-ai_activity_summary_request/${id}/`,signal);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    currentAppVersion,
    currentAnnouncements,
    isThemeVisible,
    currentTheme,
    isAnnualTargetAllowedComputed,
    isMonthlyTargetAllowedComputed,
    getAppInfo,
    getReleases,
    getAIFixText,
    getLastChangeLog,
    getActivityNoteSummary
  };
});
